//@ts-nocheck
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export enum Accessibility {
  Guests = 'GUESTS',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type AuthVerifiedResponse = {
  __typename?: 'AuthVerifiedResponse';
  accessToken: Scalars['String']['output'];
  phonePreviouslyVerified: Scalars['Boolean']['output'];
  playerId: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  tokenPairId: Scalars['String']['output'];
};

export enum AuthenticationType {
  OAuth = 'O_AUTH',
  Phone = 'PHONE'
}

export type BackofficeTokenPairResponse = {
  __typename?: 'BackofficeTokenPairResponse';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type BackofficeUserLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type BlockedPlayer = {
  __typename?: 'BlockedPlayer';
  blockedBy: PlayerSelf;
  blockedById: Scalars['String']['output'];
  blockedPlayer: PlayerSelf;
  blockedPlayerId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
};

export type BookingOption = {
  __typename?: 'BookingOption';
  clubs: Array<Club>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BookingProvider = {
  __typename?: 'BookingProvider';
  externalBookings: Array<ExternalBooking>;
  externalClubs: Array<ExternalClub>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Chat = {
  __typename?: 'Chat';
  createdAt: Scalars['DateTime']['output'];
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['String']['output']>;
  game?: Maybe<Game>;
  gameId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isGroupChat: Scalars['Boolean']['output'];
  lastMessage?: Maybe<Message>;
  lastMessageId?: Maybe<Scalars['String']['output']>;
  messages: Array<Message>;
  players: Array<PlayerToChat>;
  title?: Maybe<Scalars['String']['output']>;
  unreadMessagesCount: Scalars['Int']['output'];
};

export type CheckVersionCompatabilityInput = {
  os: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type City = {
  __typename?: 'City';
  clubs: Array<Club>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  zipCodes: Array<ZipCode>;
};

export type Club = {
  __typename?: 'Club';
  accessibility: Accessibility;
  bookingEnabled: Scalars['Boolean']['output'];
  bookingLink?: Maybe<Scalars['String']['output']>;
  bookingOptions: Array<BookingOption>;
  city: City;
  cityId: Scalars['Int']['output'];
  courts: Array<Court>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalClub?: Maybe<ExternalClub>;
  facilities: Array<Facility>;
  hidden: Scalars['Boolean']['output'];
  houseNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images: Array<ClubImage>;
  isPartner: Scalars['Boolean']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  openingHours: Array<OpeningHour>;
  paymentProcesses: Array<PaymentProcess>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  previewImages: Array<ClubPreviewImage>;
  restriction?: Maybe<Scalars['String']['output']>;
  sports: Array<ClubToSport>;
  street: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
  zipCode: ZipCode;
  zipCodeId: Scalars['Int']['output'];
};

export type ClubImage = {
  __typename?: 'ClubImage';
  club: Club;
  clubId: Scalars['String']['output'];
  clubPreviewImage?: Maybe<ClubPreviewImage>;
  clubPreviewImageId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ClubMembership = {
  __typename?: 'ClubMembership';
  club: Club;
  clubId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  player: Player;
  playerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClubMembershipSelf = {
  __typename?: 'ClubMembershipSelf';
  club: Club;
  clubId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClubPreviewImage = {
  __typename?: 'ClubPreviewImage';
  club: Club;
  clubId: Scalars['String']['output'];
  clubImage?: Maybe<ClubImage>;
  clubImageId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ClubToSport = {
  __typename?: 'ClubToSport';
  amountCourts?: Maybe<Scalars['Int']['output']>;
  club: Club;
  clubId: Scalars['String']['output'];
  courtTypes: Array<CourtType>;
  locationTypes: Array<LocationType>;
  sport: Sport;
  sportId: Scalars['Int']['output'];
};

export enum ContactOrigin {
  CirclesquareWebsite = 'CIRCLESQUARE_WEBSITE',
  PadelLeagueB2BWebsite = 'PADEL_LEAGUE_B2B_WEBSITE'
}

export type Country = {
  __typename?: 'Country';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Court = {
  __typename?: 'Court';
  clubId: Scalars['String']['output'];
  courtType?: Maybe<CourtType>;
  courtTypeId?: Maybe<Scalars['Int']['output']>;
  externalCourtId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationType?: Maybe<LocationType>;
  locationTypeId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  slots: Array<Slot>;
  visible: Scalars['Boolean']['output'];
};

export type CourtType = {
  __typename?: 'CourtType';
  clubsToSports: Array<ClubToSport>;
  courts: Array<Court>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sport: Sport;
};

export type CreateContactInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  origin: ContactOrigin;
  phone?: InputMaybe<Scalars['String']['input']>;
  topic: Scalars['String']['input'];
};

export type CreateFcmTokenInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  language?: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateGameInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  courtBooked?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  locationSubtitle?: InputMaybe<Scalars['String']['input']>;
  locationTitle: Scalars['String']['input'];
  maxPlayers: Scalars['Int']['input'];
  requestRequired?: Scalars['Boolean']['input'];
  sportId?: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: GameType;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlayerPropertyInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CreateReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  reason: ReportReason;
  reportedPlayerId: Scalars['String']['input'];
};

export type CreateScheduleSlotInput = {
  day: Day;
  scheduleSlotId: Scalars['Int']['input'];
};

export type CreateScoreResultInput = {
  gameId: Scalars['String']['input'];
  scoreSets: Array<CreateScoreSetInput>;
};

export type CreateScoreSetInput = {
  playerId: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  setNumber: Scalars['Int']['input'];
};

export enum CtaType {
  Navigation = 'NAVIGATION',
  Store = 'STORE',
  Url = 'URL'
}

export enum Day {
  Friday = 'Friday',
  Holidays = 'Holidays',
  Monday = 'Monday',
  MondayFriday = 'Monday_Friday',
  MondaySunday = 'Monday_Sunday',
  Saturday = 'Saturday',
  SaturdaySunday = 'Saturday_Sunday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type Event = {
  __typename?: 'Event';
  chats: Array<Chat>;
  club?: Maybe<Club>;
  clubId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration: Scalars['Int']['output'];
  eventType: EventType;
  guestBookingAllowed: Scalars['Boolean']['output'];
  highlighted: Scalars['Boolean']['output'];
  hosts: Array<PlayerToEvent>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCancelled: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  lat: Scalars['Float']['output'];
  leaderboard: Array<LeaderboardEntry>;
  lng: Scalars['Float']['output'];
  locationSubtitle?: Maybe<Scalars['String']['output']>;
  locationTitle: Scalars['String']['output'];
  maxPlayers: Scalars['Int']['output'];
  maxSkillLevel: SkillLevel;
  maxSkillLevelId: Scalars['Int']['output'];
  minSkillLevel: SkillLevel;
  minSkillLevelId: Scalars['Int']['output'];
  minimumPlayerCount?: Maybe<Scalars['Int']['output']>;
  minimumPlayersRequiredUntil?: Maybe<Scalars['DateTime']['output']>;
  participantCount: Scalars['Int']['output'];
  players: Array<PlayerToEvent>;
  previewImageUrl?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  refundPossibleUntil?: Maybe<Scalars['Int']['output']>;
  rounds: Array<EventRound>;
  sports: Array<EventToSport>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  tagText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  uscFree: Scalars['Boolean']['output'];
  visibleAfterExpiration: Scalars['Boolean']['output'];
  zipCode?: Maybe<ZipCode>;
  zipCodeId?: Maybe<Scalars['Int']['output']>;
};

export type EventInput = {
  name: Scalars['String']['input'];
  properties: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type EventListByFiltersInput = {
  eventTypes?: InputMaybe<Array<EventType>>;
  lang: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export enum EventParticipantRole {
  Admin = 'ADMIN',
  Host = 'HOST',
  Participant = 'PARTICIPANT',
  Trainer = 'TRAINER'
}

export type EventPaymentUpdate = {
  __typename?: 'EventPaymentUpdate';
  event: Event;
  updateTitle: Scalars['String']['output'];
};

export type EventRound = {
  __typename?: 'EventRound';
  event: Event;
  eventId: Scalars['String']['output'];
  games: Array<Game>;
  roundNumber: Scalars['Int']['output'];
};

export type EventToSport = {
  __typename?: 'EventToSport';
  event: Event;
  eventId: Scalars['String']['output'];
  sport: Sport;
  sportId: Scalars['Int']['output'];
};

export enum EventType {
  League = 'LEAGUE',
  OpenPlay = 'OPEN_PLAY',
  Other = 'OTHER',
  Training = 'TRAINING'
}

export type ExternalBooking = {
  __typename?: 'ExternalBooking';
  bookingId: Scalars['String']['output'];
  bookingProvider: BookingProvider;
  bookingProviderId: Scalars['Int']['output'];
  externalBookingId: Scalars['String']['output'];
  externalClub: ExternalClub;
  externalClubId: Scalars['String']['output'];
  externalCourt: ExternalCourt;
  externalCourtId: Scalars['String']['output'];
};

export type ExternalClub = {
  __typename?: 'ExternalClub';
  bookingProvider: BookingProvider;
  bookingProviderId: Scalars['Int']['output'];
  bookingUrl?: Maybe<Scalars['String']['output']>;
  clubId: Scalars['String']['output'];
  externalBookings: Array<ExternalBooking>;
  externalClubData: Scalars['String']['output'];
  externalClubId: Scalars['String']['output'];
  externalCourts: Array<ExternalCourt>;
  note?: Maybe<Scalars['String']['output']>;
};

export type ExternalCourt = {
  __typename?: 'ExternalCourt';
  bookingProviderId: Scalars['Int']['output'];
  courtId: Scalars['String']['output'];
  externalBookings: Array<ExternalBooking>;
  externalClub: ExternalClub;
  externalClubId: Scalars['String']['output'];
  externalCourtId: Scalars['String']['output'];
};

export type Facility = {
  __typename?: 'Facility';
  clubs: Array<Club>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type FcmToken = {
  __typename?: 'FcmToken';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  language: Language;
  languageCode: Scalars['String']['output'];
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FeatureModal = {
  __typename?: 'FeatureModal';
  ctaPayload?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  ctaType?: Maybe<CtaType>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type FeatureModalFindByVersionNumberInput = {
  lang: Scalars['String']['input'];
  platform: Platform;
  versionNumber: Scalars['String']['input'];
};

export type FeatureModalMarkAsSeenInput = {
  ctaClicked?: Scalars['Boolean']['input'];
  featureModalId: Scalars['Int']['input'];
};

export type FindClubByIdInput = {
  id: Scalars['String']['input'];
  lang: Scalars['String']['input'];
};

export type FitnessLevel = {
  __typename?: 'FitnessLevel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  players: Array<PlayerSelf>;
};

export type FitnessLevelResponse = {
  __typename?: 'FitnessLevelResponse';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Game = {
  __typename?: 'Game';
  chat: Array<Chat>;
  chatId?: Maybe<Scalars['String']['output']>;
  chats: Array<Chat>;
  courtBooked: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentScoreResult?: Maybe<ScoreResult>;
  dateIsSet: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  duration: Scalars['Int']['output'];
  eventId?: Maybe<Scalars['String']['output']>;
  eventRound?: Maybe<EventRound>;
  eventRoundNumber?: Maybe<Scalars['Int']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isOfficialCsGame: Scalars['Boolean']['output'];
  isPlayed: Scalars['Boolean']['output'];
  isRematch: Scalars['Boolean']['output'];
  joinRequests: Array<GameJoinRequest>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  locationSubtitle?: Maybe<Scalars['String']['output']>;
  locationTitle: Scalars['String']['output'];
  maxPlayers: Scalars['Int']['output'];
  ownJoinRequests: Array<GameJoinRequest>;
  players: Array<PlayerToGame>;
  requestRequired: Scalars['Boolean']['output'];
  sportId: Scalars['Int']['output'];
  startTime?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: GameType;
  updatedAt: Scalars['DateTime']['output'];
  zipCode?: Maybe<ZipCode>;
};

export type GameChange = {
  __typename?: 'GameChange';
  eventType: GameChangeEventType;
  game: Game;
  gameId: Scalars['String']['output'];
};

export enum GameChangeEventType {
  Delete = 'DELETE',
  Join = 'JOIN',
  JoinRequestAccepted = 'JOIN_REQUEST_ACCEPTED',
  JoinRequestCancelled = 'JOIN_REQUEST_CANCELLED',
  JoinRequestCreated = 'JOIN_REQUEST_CREATED',
  JoinRequestDeclined = 'JOIN_REQUEST_DECLINED',
  Leave = 'LEAVE',
  NewScoreProposal = 'NEW_SCORE_PROPOSAL',
  ScoreProposalConfirmed = 'SCORE_PROPOSAL_CONFIRMED'
}

export type GameJoinRequest = {
  __typename?: 'GameJoinRequest';
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  gameId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  player: Player;
  playerId: Scalars['String']['output'];
  status: GameJoinRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum GameJoinRequestStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export enum GameType {
  Competitive = 'COMPETITIVE',
  Friendly = 'FRIENDLY',
  League = 'LEAGUE'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export type GoogleMapsLocation = {
  __typename?: 'GoogleMapsLocation';
  address: Scalars['String']['output'];
  placeId: Scalars['String']['output'];
};

export type InitializedPayment = {
  __typename?: 'InitializedPayment';
  customerId: Scalars['String']['output'];
  paymentIntentClientSecret: Scalars['String']['output'];
  publishableKey: Scalars['String']['output'];
};

export type Language = {
  __typename?: 'Language';
  code: Scalars['String']['output'];
};

export enum LastActiveStatus {
  AMonthAgo = 'A_MONTH_AGO',
  AWeekAgo = 'A_WEEK_AGO',
  FiveDaysAgo = 'FIVE_DAYS_AGO',
  FourDaysAgo = 'FOUR_DAYS_AGO',
  JustNow = 'JUST_NOW',
  Online = 'ONLINE',
  OverAMonthAgo = 'OVER_A_MONTH_AGO',
  Recently = 'RECENTLY',
  SixDaysAgo = 'SIX_DAYS_AGO',
  ThreeDaysAgo = 'THREE_DAYS_AGO',
  ThreeWeeksAgo = 'THREE_WEEKS_AGO',
  Today = 'TODAY',
  TwoDaysAgo = 'TWO_DAYS_AGO',
  TwoWeeksAgo = 'TWO_WEEKS_AGO',
  Yesterday = 'YESTERDAY'
}

export type LeaderboardEntry = {
  __typename?: 'LeaderboardEntry';
  amountDraws: Scalars['Int']['output'];
  amountGamesPlayed: Scalars['Int']['output'];
  amountLosses: Scalars['Int']['output'];
  amountWins: Scalars['Int']['output'];
  playerId: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
};

export type LessonsReceivedResponse = {
  __typename?: 'LessonsReceivedResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ListClubsByRangeInput = {
  lang: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  range: Scalars['Int']['input'];
};

export type ListEventInput = {
  eventTypes?: InputMaybe<Array<EventType>>;
  lang?: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type ListGamesByFiltersInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type ListLastMessagesInput = {
  amount: Scalars['Int']['input'];
  chatId: Scalars['String']['input'];
};

export type ListMissingMessagesInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  asc: Scalars['Boolean']['input'];
  chatId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};

export type ListNotificationsByPlayerInput = {
  offset: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type LocationAutocompleteInput = {
  address: Scalars['String']['input'];
  languageCode?: InputMaybe<Scalars['String']['input']>;
  sessionToken?: InputMaybe<Scalars['String']['input']>;
};

export type LocationAutocompleteResponse = {
  __typename?: 'LocationAutocompleteResponse';
  predictions: Array<GoogleMapsLocation>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

export type LocationDetailsInput = {
  languageCode?: InputMaybe<Scalars['String']['input']>;
  placeId: Scalars['String']['input'];
  sessionToken?: InputMaybe<Scalars['String']['input']>;
};

export type LocationDetailsResponse = {
  __typename?: 'LocationDetailsResponse';
  admAreaL1?: Maybe<Scalars['String']['output']>;
  admAreaL2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  sublocality?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  clubsToSports: Array<ClubToSport>;
  courts: Array<Court>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LookingForResponse = {
  __typename?: 'LookingForResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  chat: Chat;
  chatId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  contents: Array<MessageContent>;
  id: Scalars['String']['output'];
  internalId: Scalars['Int']['output'];
  isSystemMessage: Scalars['Boolean']['output'];
  onlyVisibleToSubject: Scalars['Boolean']['output'];
  replies: Array<Message>;
  replyTo?: Maybe<Message>;
  replyToId?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Player>;
  senderId?: Maybe<Scalars['String']['output']>;
  senderIsSelf: Scalars['Boolean']['output'];
  sentAt: Scalars['DateTime']['output'];
  subject?: Maybe<Player>;
  subjectId?: Maybe<Scalars['String']['output']>;
  transientId: Scalars['String']['output'];
  type?: Maybe<MessageType>;
};

export type MessageContent = {
  __typename?: 'MessageContent';
  content: Scalars['String']['output'];
  language: Language;
  languageCode: Scalars['String']['output'];
  message: Message;
  messageId: Scalars['String']['output'];
};

export enum MessageType {
  ChatJoined = 'CHAT_JOINED',
  ChatLeft = 'CHAT_LEFT'
}

export type Mutation = {
  __typename?: 'Mutation';
  addFcmToken: FcmToken;
  authAppleOAuth: AuthVerifiedResponse;
  authConvertFromPreviousVersion: TokenPair;
  authGetPhoneVerificationCode: PhoneVerificationCodeResponse;
  authGoogleOAuth: AuthVerifiedResponse;
  authRefreshToken: TokenPair;
  authVerifyPhoneNumber: AuthVerifiedResponse;
  backofficeLogin: BackofficeTokenPairResponse;
  calculateInitialRating: Scalars['Boolean']['output'];
  chatCreate: Chat;
  chatLeave: Scalars['Boolean']['output'];
  chatRead: Scalars['Boolean']['output'];
  chatSendMessage: Message;
  clubMembershipAdd: ClubMembershipSelf;
  clubMembershipRemove: Scalars['Boolean']['output'];
  clubSuggestionCreate: Scalars['Boolean']['output'];
  contactCreate: Scalars['Boolean']['output'];
  evaluateHistoricGames: Scalars['Boolean']['output'];
  eventJoin: PlayerToEvent;
  eventLeave: Event;
  eventReportProblem: Scalars['Boolean']['output'];
  featureModalMarkAsSeen: Scalars['Boolean']['output'];
  gameCreate: Game;
  gameDelete: Scalars['Boolean']['output'];
  gameJoin: PlayerToGame;
  gameJoinRequestAccept: Game;
  gameJoinRequestCancel: GameJoinRequest;
  gameJoinRequestCreate: GameJoinRequest;
  gameJoinRequestDecline: GameJoinRequest;
  gameLeave: Scalars['Boolean']['output'];
  gameScoreResultCreate: ScoreResult;
  notificationMarkMultipleAsSeen: Scalars['Boolean']['output'];
  padelLeagueCheckoutSessionCreate: Scalars['String']['output'];
  paymentInitializeForEvent: InitializedPayment;
  playerAddFavoriteClub: Scalars['Boolean']['output'];
  playerBlockPlayer: BlockedPlayer;
  playerDeleteSelf: Scalars['Boolean']['output'];
  playerEditProfile: PlayerSelf;
  playerEditSchedule: Array<PlayerToScheduleSlot>;
  playerOnboardBase: PlayerSelf;
  playerOnboardGuest: PlayerSelf;
  playerOnboardSport: PlayerSelf;
  playerRemoveFavoriteClub: Scalars['Boolean']['output'];
  playerSetAppLanguage: Scalars['Boolean']['output'];
  playerSetLocation: PlayerSelf;
  playerSetUscMembership: Scalars['Boolean']['output'];
  playerToSportEdit: PlayerToSportSelf;
  playerUnblockPlayer: Scalars['Boolean']['output'];
  playerUploadProfilePicture: Scalars['String']['output'];
  refreshToken: BackofficeTokenPairResponse;
  reportPlayer: Report;
  trackingPushEvents: Scalars['Boolean']['output'];
  trackingSetPlayerProperties: Scalars['Boolean']['output'];
};


export type MutationAddFcmTokenArgs = {
  data: CreateFcmTokenInput;
};


export type MutationAuthAppleOAuthArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
  origin?: Scalars['String']['input'];
};


export type MutationAuthConvertFromPreviousVersionArgs = {
  authToken: Scalars['String']['input'];
};


export type MutationAuthGetPhoneVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationAuthGoogleOAuthArgs = {
  idToken: Scalars['String']['input'];
  origin?: Scalars['String']['input'];
};


export type MutationAuthVerifyPhoneNumberArgs = {
  data: VerifyPhoneNumberInput;
};


export type MutationBackofficeLoginArgs = {
  data: BackofficeUserLoginInput;
};


export type MutationChatCreateArgs = {
  playerId: Scalars['String']['input'];
};


export type MutationChatLeaveArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationChatReadArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationChatSendMessageArgs = {
  data: SendMessageInput;
};


export type MutationClubMembershipAddArgs = {
  clubId: Scalars['String']['input'];
};


export type MutationClubMembershipRemoveArgs = {
  clubId: Scalars['String']['input'];
};


export type MutationClubSuggestionCreateArgs = {
  name: Scalars['String']['input'];
};


export type MutationContactCreateArgs = {
  data: CreateContactInput;
};


export type MutationEventJoinArgs = {
  eventId: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEventLeaveArgs = {
  eventId: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEventReportProblemArgs = {
  data: ReportProblemInput;
};


export type MutationFeatureModalMarkAsSeenArgs = {
  data: FeatureModalMarkAsSeenInput;
};


export type MutationGameCreateArgs = {
  data: CreateGameInput;
};


export type MutationGameDeleteArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationGameJoinArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationGameJoinRequestAcceptArgs = {
  gameJoinRequestId: Scalars['String']['input'];
};


export type MutationGameJoinRequestCancelArgs = {
  gameJoinRequestId: Scalars['String']['input'];
};


export type MutationGameJoinRequestCreateArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationGameJoinRequestDeclineArgs = {
  gameJoinRequestId: Scalars['String']['input'];
};


export type MutationGameLeaveArgs = {
  gameId: Scalars['String']['input'];
};


export type MutationGameScoreResultCreateArgs = {
  data: CreateScoreResultInput;
};


export type MutationNotificationMarkMultipleAsSeenArgs = {
  data: NotificationMarkMultipleAsSeenInput;
};


export type MutationPadelLeagueCheckoutSessionCreateArgs = {
  data: PadelLeagueCheckoutSessionCreateInput;
};


export type MutationPaymentInitializeForEventArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationPlayerAddFavoriteClubArgs = {
  clubId: Scalars['String']['input'];
};


export type MutationPlayerBlockPlayerArgs = {
  playerId: Scalars['String']['input'];
};


export type MutationPlayerEditProfileArgs = {
  data: PlayerEditProfileInput;
};


export type MutationPlayerEditScheduleArgs = {
  data: Array<CreateScheduleSlotInput>;
};


export type MutationPlayerOnboardBaseArgs = {
  data: PlayerOnboardBaseInput;
};


export type MutationPlayerOnboardGuestArgs = {
  firstName: Scalars['String']['input'];
};


export type MutationPlayerOnboardSportArgs = {
  data: PlayerOnboardSportInput;
};


export type MutationPlayerRemoveFavoriteClubArgs = {
  clubId: Scalars['String']['input'];
};


export type MutationPlayerSetAppLanguageArgs = {
  languageCode: Scalars['String']['input'];
};


export type MutationPlayerSetLocationArgs = {
  data: PlayerSetLocationInput;
};


export type MutationPlayerSetUscMembershipArgs = {
  isMember: Scalars['Boolean']['input'];
};


export type MutationPlayerToSportEditArgs = {
  data: UpdatePlayerToSportInput;
};


export type MutationPlayerUnblockPlayerArgs = {
  playerId: Scalars['String']['input'];
};


export type MutationPlayerUploadProfilePictureArgs = {
  file: Scalars['String']['input'];
};


export type MutationReportPlayerArgs = {
  data: CreateReportInput;
};


export type MutationTrackingPushEventsArgs = {
  data: Array<EventInput>;
};


export type MutationTrackingSetPlayerPropertiesArgs = {
  data: Array<CreatePlayerPropertyInput>;
};

export type Notification = {
  __typename?: 'Notification';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  cta: Scalars['String']['output'];
  deeplink?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
  seen: Scalars['Boolean']['output'];
  seenAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type: NotificationType;
};

export type NotificationMarkMultipleAsSeenInput = {
  notificationIds: Array<Scalars['String']['input']>;
};

export enum NotificationType {
  EventUpdate = 'EVENT_UPDATE',
  FeatureModal = 'FEATURE_MODAL',
  GameJoinRequest = 'GAME_JOIN_REQUEST',
  GameUpdate = 'GAME_UPDATE',
  MatchingGame = 'MATCHING_GAME',
  UpdateAvailable = 'UPDATE_AVAILABLE'
}

export type OpeningHour = {
  __typename?: 'OpeningHour';
  closeTime: Scalars['String']['output'];
  club: Club;
  clubId: Scalars['String']['output'];
  day: Day;
  id: Scalars['String']['output'];
  openTime: Scalars['String']['output'];
};

export type PadelLeagueCheckoutSessionCreateInput = {
  seasons: Scalars['Int']['input'];
  teams: Scalars['Int']['input'];
};

export type PadelLeagueCheckoutSessionResponse = {
  __typename?: 'PadelLeagueCheckoutSessionResponse';
  amountDiscount: Scalars['Float']['output'];
  amountSubtotal: Scalars['Float']['output'];
  amountTax: Scalars['Float']['output'];
  amountTotal: Scalars['Float']['output'];
  customer: StripeCustomerDetails;
  id: Scalars['String']['output'];
  paymentStatus: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type PastActivity = {
  __typename?: 'PastActivity';
  event?: Maybe<Event>;
  game?: Maybe<Game>;
};

export type PaymentProcess = {
  __typename?: 'PaymentProcess';
  clubs: Array<Club>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PhoneVerificationCodeResponse = {
  __typename?: 'PhoneVerificationCodeResponse';
  phoneNumber: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  verificationCode?: Maybe<Scalars['String']['output']>;
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Player = {
  __typename?: 'Player';
  age?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Float']['output']>;
  clubMembership?: Maybe<Scalars['String']['output']>;
  clubMemberships: Array<ClubMembership>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isGuest: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  languages: Array<Language>;
  lastActiveStatus?: Maybe<LastActiveStatus>;
  lastName?: Maybe<Scalars['String']['output']>;
  profilePictureSmallUrl?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  scheduleSlots: Array<PlayerToScheduleSlot>;
  sports: Array<PlayerToSport>;
  tennisLk?: Maybe<Scalars['Int']['output']>;
};

export type PlayerEditProfileInput = {
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  clubMembership?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  languageCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  tennisLk?: InputMaybe<Scalars['Int']['input']>;
};

export type PlayerListByFiltersInput = {
  gender?: InputMaybe<Gender>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  radius?: InputMaybe<Scalars['Float']['input']>;
  skillLevelIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sportId?: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type PlayerOnboardBaseInput = {
  age: Scalars['Int']['input'];
  firstName: Scalars['String']['input'];
  gender: Gender;
  languageCode: Scalars['String']['input'];
};

export type PlayerOnboardSportInput = {
  fitnessLevelId?: InputMaybe<Scalars['Int']['input']>;
  lessonsReceivedId?: InputMaybe<Scalars['Int']['input']>;
  lookingForIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  playingExperienceId?: InputMaybe<Scalars['Int']['input']>;
  playingFrequencyId?: InputMaybe<Scalars['Int']['input']>;
  skillLevelId: Scalars['Int']['input'];
  sportId: Scalars['Int']['input'];
};

export type PlayerSelf = {
  __typename?: 'PlayerSelf';
  age?: Maybe<Scalars['Int']['output']>;
  appLanguage?: Maybe<Language>;
  appLanguageCode?: Maybe<Scalars['String']['output']>;
  appleOAuthId?: Maybe<Scalars['String']['output']>;
  authenticationType?: Maybe<AuthenticationType>;
  birthdate?: Maybe<Scalars['DateTime']['output']>;
  blockedPlayers: Array<BlockedPlayer>;
  chats: Array<PlayerToChatSelf>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']['output']>;
  clubMembership?: Maybe<Scalars['String']['output']>;
  clubMemberships: Array<ClubMembershipSelf>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  events: Array<PlayerToEventSelf>;
  favoriteClubs: Array<Club>;
  fcmTokens: Array<FcmToken>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitnessLevel?: Maybe<FitnessLevel>;
  fitnessLevelId?: Maybe<Scalars['Int']['output']>;
  gameJoinRequests: Array<GameJoinRequest>;
  games: Array<PlayerToGameSelf>;
  gender?: Maybe<Gender>;
  googleOAuthId?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isGuest: Scalars['Boolean']['output'];
  isGuestOnboarded: Scalars['Boolean']['output'];
  isUscMember: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  languages: Array<Language>;
  lastName?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  matchingGameNotificationLastReceived?: Maybe<Scalars['DateTime']['output']>;
  messages: Array<Message>;
  oAuthEmail?: Maybe<Scalars['String']['output']>;
  onboarded: Scalars['Boolean']['output'];
  onboardedSport: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phonePreviouslyVerified: Scalars['Boolean']['output'];
  phoneVerificationCode?: Maybe<Scalars['String']['output']>;
  phoneVerificationCodeInvalidCounter?: Maybe<Scalars['Float']['output']>;
  phoneVerified: Scalars['Boolean']['output'];
  profilePictureSmallUrl?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  scheduleSlots: Array<PlayerToScheduleSlot>;
  specificBirthdateSet: Scalars['Boolean']['output'];
  sports: Array<PlayerToSportSelf>;
  systemMessages: Array<Message>;
  tennisLk?: Maybe<Scalars['Int']['output']>;
};

export type PlayerSelfHistoricActivitiesInput = {
  offset: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type PlayerSetLocationInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type PlayerToChat = {
  __typename?: 'PlayerToChat';
  chat: Chat;
  chatId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  isAdmin: Scalars['Boolean']['output'];
  leftAt?: Maybe<Scalars['DateTime']['output']>;
  player: Player;
  playerId: Scalars['String']['output'];
};

export type PlayerToChatSelf = {
  __typename?: 'PlayerToChatSelf';
  chat: Chat;
  chatId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  isAdmin: Scalars['Boolean']['output'];
  leftAt?: Maybe<Scalars['DateTime']['output']>;
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
};

export type PlayerToEvent = {
  __typename?: 'PlayerToEvent';
  createdAt: Scalars['DateTime']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  player: Player;
  playerId: Scalars['String']['output'];
  role: EventParticipantRole;
};

export type PlayerToEventSelf = {
  __typename?: 'PlayerToEventSelf';
  createdAt: Scalars['DateTime']['output'];
  event: Event;
  eventId: Scalars['String']['output'];
  paymentIntentId?: Maybe<Scalars['String']['output']>;
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
  role: EventParticipantRole;
};

export type PlayerToGame = {
  __typename?: 'PlayerToGame';
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  gameId: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  player: Player;
  playerId: Scalars['String']['output'];
  ratingChange?: Maybe<Scalars['Float']['output']>;
};

export type PlayerToGameSelf = {
  __typename?: 'PlayerToGameSelf';
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  gameId: Scalars['String']['output'];
  isAdmin: Scalars['Boolean']['output'];
  playerId: Scalars['String']['output'];
  ratingChange?: Maybe<Scalars['Float']['output']>;
};

export type PlayerToScheduleSlot = {
  __typename?: 'PlayerToScheduleSlot';
  day: Day;
  playerId: Scalars['String']['output'];
  scheduleSlot: ScheduleSlot;
  scheduleSlotId: Scalars['Int']['output'];
};

export type PlayerToScoreResult = {
  __typename?: 'PlayerToScoreResult';
  player: Player;
  playerId: Scalars['String']['output'];
  result: ScoreResultOption;
  scoreResult: ScoreResult;
  scoreResultId: Scalars['String']['output'];
};

export type PlayerToSport = {
  __typename?: 'PlayerToSport';
  player: Player;
  playerId: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  ratingAccuracy: Scalars['Float']['output'];
  skillLevel: SkillLevelResponse;
  skillLevelId: Scalars['Int']['output'];
  sport: Sport;
  sportId: Scalars['Int']['output'];
};

export type PlayerToSportSelf = {
  __typename?: 'PlayerToSportSelf';
  canEditSkillLevel: Scalars['Boolean']['output'];
  lessonsReceived?: Maybe<LessonsReceivedResponse>;
  lessonsReceivedId?: Maybe<Scalars['Int']['output']>;
  lookingFor?: Maybe<Array<LookingForResponse>>;
  player: PlayerSelf;
  playerId: Scalars['String']['output'];
  playingExperience?: Maybe<PlayingExperienceResponse>;
  playingExperienceId?: Maybe<Scalars['Int']['output']>;
  playingFrequency?: Maybe<PlayingFrequency>;
  playingFrequencyId?: Maybe<Scalars['Int']['output']>;
  rating: Scalars['Float']['output'];
  ratingAccuracy: Scalars['Float']['output'];
  skillLevel: SkillLevelResponse;
  skillLevelId: Scalars['Int']['output'];
  sport: Sport;
  sportId: Scalars['Int']['output'];
};

export type PlayingExperienceResponse = {
  __typename?: 'PlayingExperienceResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PlayingFrequency = {
  __typename?: 'PlayingFrequency';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  playersToSports: Array<PlayerToSportSelf>;
};

export type PlayingFrequencyResponse = {
  __typename?: 'PlayingFrequencyResponse';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  chat: Chat;
  chatListAllMissingMessages: Array<Message>;
  chatListLastMessages: Array<Message>;
  chatListMissingMessages: Array<Message>;
  chats: Array<Chat>;
  city: City;
  club: Club;
  clubs: Array<Club>;
  clubsListByRange: Array<Club>;
  coreCheckVersionCompatability: VersionCompatability;
  event: Event;
  eventListByFilter: Array<Event>;
  eventListOwn: Array<Event>;
  eventListPublic: Array<Event>;
  eventPublic: Event;
  featureModalFindByVersionNumber?: Maybe<FeatureModal>;
  game: Game;
  gameListByFilter: Array<Game>;
  gameListOwn: Array<Game>;
  locationAutocomplete: LocationAutocompleteResponse;
  locationDetails: LocationDetailsResponse;
  notifications: Array<Notification>;
  padelLeagueCheckoutSession: PadelLeagueCheckoutSessionResponse;
  player: Player;
  playerFitnessLevels: Array<FitnessLevelResponse>;
  playerLessonsReceiveds: Array<LessonsReceivedResponse>;
  playerListByClub: Array<Player>;
  playerListByFilters: Array<Player>;
  playerLookingFors: Array<LookingForResponse>;
  playerPlayingExperiences: Array<PlayingFrequencyResponse>;
  playerSearchByName: Array<Player>;
  playerSearchByNameAndClubMembership: Array<Player>;
  playerSelf: PlayerSelf;
  playerSelfHistoricActivities: Array<PastActivity>;
  playerSkillLevels: Array<SkillLevelResponse>;
  players: Array<Player>;
  slotListByClub: Array<SlotResponse>;
  sport: Sport;
  sports: Array<Sport>;
  zipCode: ZipCode;
};


export type QueryChatArgs = {
  chatId: Scalars['String']['input'];
};


export type QueryChatListAllMissingMessagesArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryChatListLastMessagesArgs = {
  data: ListLastMessagesInput;
};


export type QueryChatListMissingMessagesArgs = {
  data: ListMissingMessagesInput;
};


export type QueryCityArgs = {
  id: Scalars['Int']['input'];
};


export type QueryClubArgs = {
  data: FindClubByIdInput;
};


export type QueryClubsListByRangeArgs = {
  data: ListClubsByRangeInput;
};


export type QueryCoreCheckVersionCompatabilityArgs = {
  data: CheckVersionCompatabilityInput;
};


export type QueryEventArgs = {
  id: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventListByFilterArgs = {
  data: EventListByFiltersInput;
};


export type QueryEventListOwnArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEventListPublicArgs = {
  data: ListEventInput;
};


export type QueryEventPublicArgs = {
  id: Scalars['String']['input'];
  lang?: Scalars['String']['input'];
};


export type QueryFeatureModalFindByVersionNumberArgs = {
  data: FeatureModalFindByVersionNumberInput;
};


export type QueryGameArgs = {
  id: Scalars['String']['input'];
};


export type QueryGameListByFilterArgs = {
  data: ListGamesByFiltersInput;
};


export type QueryLocationAutocompleteArgs = {
  data: LocationAutocompleteInput;
};


export type QueryLocationDetailsArgs = {
  data: LocationDetailsInput;
};


export type QueryNotificationsArgs = {
  data: ListNotificationsByPlayerInput;
};


export type QueryPadelLeagueCheckoutSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QueryPlayerArgs = {
  id: Scalars['String']['input'];
};


export type QueryPlayerListByClubArgs = {
  data: PlayerListByFiltersInput;
};


export type QueryPlayerListByFiltersArgs = {
  data: PlayerListByFiltersInput;
};


export type QueryPlayerSearchByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryPlayerSearchByNameAndClubMembershipArgs = {
  name: Scalars['String']['input'];
};


export type QueryPlayerSelfHistoricActivitiesArgs = {
  data: PlayerSelfHistoricActivitiesInput;
};


export type QuerySlotListByClubArgs = {
  clubId: Scalars['String']['input'];
};


export type QuerySportArgs = {
  id: Scalars['Int']['input'];
};


export type QueryZipCodeArgs = {
  id: Scalars['Int']['input'];
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  reason?: Maybe<ReportReason>;
  reportedBy: Player;
  reportedById: Scalars['String']['output'];
  reportedPlayer: Player;
  reportedPlayerId: Scalars['String']['output'];
};

export type ReportProblemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  gameId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export enum ReportReason {
  FakeProfile = 'FAKE_PROFILE',
  HateSpeech = 'HATE_SPEECH',
  Other = 'OTHER',
  SexualContent = 'SEXUAL_CONTENT',
  Violence = 'VIOLENCE'
}

export type ScheduleSlot = {
  __typename?: 'ScheduleSlot';
  endTime: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type ScoreResult = {
  __typename?: 'ScoreResult';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  players: Array<PlayerToScoreResult>;
  proposedBy: Player;
  proposedById: Scalars['String']['output'];
  scoreSets: Array<ScoreSet>;
  updatedAt: Scalars['DateTime']['output'];
  winners: Array<Player>;
};

export enum ScoreResultOption {
  Draw = 'DRAW',
  Loss = 'LOSS',
  Win = 'WIN'
}

export type ScoreResultSelf = {
  __typename?: 'ScoreResultSelf';
  createdAt: Scalars['DateTime']['output'];
  game: Game;
  gameId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  proposedById: Scalars['String']['output'];
  scoreSets: Array<ScoreSetSelf>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ScoreSet = {
  __typename?: 'ScoreSet';
  id: Scalars['String']['output'];
  player: Player;
  playerId: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  scoreResult: ScoreResult;
  scoreResultId: Scalars['String']['output'];
  setNumber: Scalars['Int']['output'];
  setWon: Scalars['Boolean']['output'];
};

export type ScoreSetSelf = {
  __typename?: 'ScoreSetSelf';
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  scoreResult: ScoreResultSelf;
  scoreResultId: Scalars['String']['output'];
  setNumber: Scalars['Int']['output'];
  setWon: Scalars['Boolean']['output'];
};

export type SendMessageInput = {
  chatId: Scalars['String']['input'];
  content: Scalars['String']['input'];
  replyToId?: InputMaybe<Scalars['String']['input']>;
  transientId: Scalars['String']['input'];
};

export type SkillLevel = {
  __typename?: 'SkillLevel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  playersToSports: Array<PlayerToSportSelf>;
};

export type SkillLevelResponse = {
  __typename?: 'SkillLevelResponse';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Slot = {
  __typename?: 'Slot';
  court: Court;
  courtId: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isBookable: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export type SlotResponse = {
  __typename?: 'SlotResponse';
  bookableUntil?: Maybe<Scalars['DateTime']['output']>;
  bookingId?: Maybe<Scalars['String']['output']>;
  courtId: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isBookable: Scalars['Boolean']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  startTime: Scalars['DateTime']['output'];
};

export type Sport = {
  __typename?: 'Sport';
  clubs: Array<ClubToSport>;
  courtTypes: Array<CourtType>;
  events: Array<EventToSport>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type StripeCustomerDetails = {
  __typename?: 'StripeCustomerDetails';
  address: StripeCustomerDetailsAddress;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type StripeCustomerDetailsAddress = {
  __typename?: 'StripeCustomerDetailsAddress';
  city: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  eventPaymentUpdated: EventPaymentUpdate;
  gameChanged: GameChange;
  messageSent: Message;
  newNotification: Notification;
};

export type TokenPair = {
  __typename?: 'TokenPair';
  accessToken: Scalars['String']['output'];
  id: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type UpdatePlayerToSportInput = {
  skillLevelId: Scalars['Int']['input'];
  sportId: Scalars['Int']['input'];
};

export type VerifyPhoneNumberInput = {
  phoneVerificationCode: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};

export type VersionCompatability = {
  __typename?: 'VersionCompatability';
  isSupported: Scalars['Boolean']['output'];
  supportedUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type ZipCode = {
  __typename?: 'ZipCode';
  city: City;
  cityId: Scalars['Int']['output'];
  clubs: Array<Club>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  zip: Scalars['String']['output'];
};

export type ContactCreateMutationVariables = Exact<{
  data: CreateContactInput;
}>;


export type ContactCreateMutation = { __typename?: 'Mutation', contactCreate: boolean };

export type PadelLeagueCheckoutSessionCreateMutationVariables = Exact<{
  data: PadelLeagueCheckoutSessionCreateInput;
}>;


export type PadelLeagueCheckoutSessionCreateMutation = { __typename?: 'Mutation', padelLeagueCheckoutSessionCreate: string };

export type PadelLeagueCheckoutSessionQueryVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type PadelLeagueCheckoutSessionQuery = { __typename?: 'Query', padelLeagueCheckoutSession: { __typename?: 'PadelLeagueCheckoutSessionResponse', id: string, status: string, paymentStatus: string, amountSubtotal: number, amountTax: number, amountTotal: number, amountDiscount: number, customer: { __typename?: 'StripeCustomerDetails', email: string, name: string, phone?: string | null, address: { __typename?: 'StripeCustomerDetailsAddress', city: string, country?: string | null, line1: string, line2?: string | null, postalCode: string, state?: string | null } } } };

export const ContactCreateDocument = gql`
    mutation ContactCreate($data: CreateContactInput!) {
  contactCreate(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactCreateGQL extends Apollo.Mutation<ContactCreateMutation, ContactCreateMutationVariables> {
    document = ContactCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PadelLeagueCheckoutSessionCreateDocument = gql`
    mutation PadelLeagueCheckoutSessionCreate($data: PadelLeagueCheckoutSessionCreateInput!) {
  padelLeagueCheckoutSessionCreate(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PadelLeagueCheckoutSessionCreateGQL extends Apollo.Mutation<PadelLeagueCheckoutSessionCreateMutation, PadelLeagueCheckoutSessionCreateMutationVariables> {
    document = PadelLeagueCheckoutSessionCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PadelLeagueCheckoutSessionDocument = gql`
    query PadelLeagueCheckoutSession($sessionId: String!) {
  padelLeagueCheckoutSession(sessionId: $sessionId) {
    id
    status
    paymentStatus
    amountSubtotal
    amountTax
    amountTotal
    amountDiscount
    customer {
      email
      name
      phone
      address {
        city
        country
        line1
        line2
        postalCode
        state
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PadelLeagueCheckoutSessionGQL extends Apollo.Query<PadelLeagueCheckoutSessionQuery, PadelLeagueCheckoutSessionQueryVariables> {
    document = PadelLeagueCheckoutSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
